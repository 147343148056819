import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { broadcastListReducer, broadcastDetailReducer } from './reducers/broadcastReducers'
import  tagSelectionSliceReducer from './features/tagSelection/tagSelection'
import statusSelectionSliceReduer from './features/statusSelection.js/statusSelection'

const reducer = combineReducers({
  broadcastList: broadcastListReducer,
  broadcastDetail: broadcastDetailReducer,
})

const middleware = [thunk]

const store = configureStore({
  reducer: {
    broadcastList: broadcastListReducer,
    broadcastDetail: broadcastDetailReducer,
    tagSelection: tagSelectionSliceReducer,
    statusSelection: statusSelectionSliceReduer,
  },
  middleware: [...middleware],
  devTools: true,
})

export default store
