import {
  BROADCAST_LIST_REQUEST,
  BROADCAST_LIST_SUCCESS,
  BROADCAST_LIST_FAIL,
  BRADCAST_LIST_SET_STATUS_FILTER,
  BROADCAST_DETAIL_REQUEST,
  BROADCAST_DETAIL_SUCCESS,
  BROADCAST_DETAIL_FAIL,
  FILTER_BROADCASTS_BY_STATUS,
  FILTER_BROADCASTS_BY_QUERY,
} from "../constants/broadcastConstants"

export const broadcastListReducer = (state = { broadcasts: [], query: [] }, action) => {
  switch (action.type) {
    case BROADCAST_LIST_REQUEST:
      return { loading: true, broadcasts: [], query: 'salkdfj' }
    case BROADCAST_LIST_SUCCESS:
      return { loading: false, broadcasts: action.payload }
    case BROADCAST_LIST_FAIL:
      // eg bad data, or no internet connection
      return { loading: false, error: action.payload }
    case FILTER_BROADCASTS_BY_STATUS:
      return { ...state, filteredBroadcasts: action.payload.broadcasts }
    case FILTER_BROADCASTS_BY_QUERY:
      return { ...state, query: action.payload.query }
    case BRADCAST_LIST_SET_STATUS_FILTER:
      return { ...state, statusFilter: action.payload }
    default:
      // if we don't recognize the action type, we just return the state as-is
      return state
  }
}

// TODO: This is a hacky way to filter broadcasts by query. I'm not sure how to do it properly.
// Figure out the proper way to implement this with Redux Toolkit.
export function getBroadcastsFilteredByQuery(payload, filteredTags, filteredStatus) {
  const { broadcasts, query } = payload

  let filteredBroadcasts = broadcasts

  // Apply status filter
  switch (filteredStatus) {
    case "live":
      filteredBroadcasts = filteredBroadcasts.filter((broadcast) => broadcast.is_live === true)
      break
    case "upcoming":
      filteredBroadcasts = filteredBroadcasts.filter((broadcast) => broadcast.is_live === false)
      break
    default:
      break
  }

  if (filteredTags != null) {
    // Apply tag filters
    // TODO: This is a hacky way to filter broadcasts by tag. I'm not sure how to do it properly.
    if (filteredTags.includes("Unknown location")) {
      filteredBroadcasts = filteredBroadcasts.filter((broadcast) => broadcast.tags.length === 0)
    } else {
      filteredBroadcasts = filteredBroadcasts.filter((broadcast) => {
        let broadcastTags = broadcast.tags.map((tag) => tag.name)
        return filteredTags.every((tag) => broadcastTags.includes(tag))
      })
    }
  }

  if (query === "") {
    return filteredBroadcasts
  }
  return filteredBroadcasts.filter(
    (filteredBroadcast) =>
    filteredBroadcast.title.match(new RegExp(query, 'i') ) ||
    filteredBroadcast.channel.title.match(new RegExp(query, 'i'))
  )
}

export const broadcastDetailReducer = (state = { broadcast: {} }, action) => {
  switch (action.type) {
    case BROADCAST_DETAIL_REQUEST:
      return { loading: true, broadcast: [] }
    case BROADCAST_DETAIL_SUCCESS:
      return { loading: false, broadcast: action.payload }
    case BROADCAST_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
