import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'


import Message from '../components/Message'
import { listBroadcasts } from '../actions/broadcastActions'
import Broadcast from '../components/Broadcast';
import BroadcastSkeleton from '../components/BroadcastSkeleton';
import { getBroadcastsFilteredByQuery } from '../reducers/broadcastReducers'
import { resetSearchFormInput } from '../components/Header'
import { createSelector } from '@reduxjs/toolkit';

export default function BroadcastGridScreen({filteredTag, setFilteredTag, tagStatuses, setTagStatuses, toggleValue, registerKeyValuePair}) {
  const dispatch = useDispatch()
  const broadcastList = useSelector(state => state.broadcastList)
  const tagSelection = useSelector(state => state.tagSelection)
  const statusSelection = useSelector(state => state.statusSelection)

  const {error, loading, broadcasts, query} = broadcastList
  const {filteredTags} = tagSelection
  const {filteredStatus} = statusSelection

  const visibleBroadcasts = getBroadcastsFilteredByQuery(broadcastList, filteredTags, filteredStatus)
  // const [filteredTag, setFilteredTag] = useState('')
  // const [filteredBroadcasts, setFilteredBroadcasts] = useState([])

  // const [tagStatuses, setTagStatuses] = React.useState({
  //   'Unknown location': false,
  // });

    // This function will toggle the value for a given key in the dictionary
  // const toggleValue = key => {
  //   setTagStatuses({
  //     ...tagStatuses,
  //     [key]: !tagStatuses[key]
  //   });
  // }

  // This function will add a new key-value pair to the dictionary if the key
  // is not already in the dictionary
  // const registerKeyValuePair = (key, value) => {
  //   if (!(key in tagStatuses)) {
  //     setTagStatuses({
  //       ...tagStatuses,
  //       [key]: value
  //     });
  //   }
  // }

  let previousBroadcastCount = 6

  useEffect(() => {
    dispatch(listBroadcasts())

    const interval = setInterval(() => {
      dispatch(listBroadcasts())
      resetSearchFormInput()
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const selectedTags = useSelector(
    state => state.selectedTags
  )

  return (
    <div className="pb-32 bg-gray-100 text-gray-700">
      {
        loading ?
          <div className="container mx-auto px-6 lg:px-0 pt-10">
            <div className="lg:grid grid-cols-3 gap-10 lg:space-y-0 space-y-8 item">
              {[...Array(previousBroadcastCount)].map((e, i) => <BroadcastSkeleton key={i} />)}
            </div>
          </div>
        : error ? <Message variant='danger'>{error}</Message>
        :
          <div className="container mx-auto px-6 lg:px-0 pt-10">
              <div className="lg:grid grid-cols-6 gap-10 lg:space-y-0 space-y-8 item">
                  {visibleBroadcasts.map((broadcast) => (
                    <Broadcast key={broadcast._id} broadcast={broadcast} filteredTag={filteredTag} onFilteredTagChange={event => setFilteredTag(event.target.value)} tagStatuses={tagStatuses} toggleValue={toggleValue} registerKeyValuePair={registerKeyValuePair}/>
                  ))}
              </div>
          </div>
  }
    </div>
  )
}
