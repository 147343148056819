import ReactGA from "react-ga4";

const broadcastAddedToCalendar = (platform_id, channel_title, broadcast_title) => {
  ReactGA.event({
    category: "Broadcast",
    action: "Added to Calendar",
    label: `${platform_id} - ${channel_title} - ${broadcast_title}`,
  })
}

const broadcastFilterQuery = (query) => {
  ReactGA.event({
    category: "Broadcast",
    action: "Filtered by query",
    label: query,
  })
}

const broadcastsFilteredByTag = (tag) => {
  ReactGA.event({
    category: "Broadcast",
    action: "Filtered by tag",
    label: tag,
  })
}

const broadcastFilteredByStatus = (status) => {
  ReactGA.event({
    category: "Broadcast",
    action: "Filtered by status",
    label: status,
  })
}

const broadcastsPreviewLiveBroadcast = (platform_id, channel_title, broadcast_title) => {
  ReactGA.event({
    category: "Broadcast",
    action: "Previewed a live broadcast",
    label: `${platform_id} - ${channel_title} - ${broadcast_title}`,
  })
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_content
const broadcastReferral = (platform_id, channel_title, broadcast_title) => {
  ReactGA.event({
    category: "Broadcast",
    action: "Opened a broadcast",
    label: `${channel_title} - ${platform_id}: ${broadcast_title}`,
  })
}

const bannerDismissed = () => {
  ReactGA.event({
    category: "Banner",
    action: "Dismissed banner",
  })
}

const navigateToAnalytics = () => {
  ReactGA.event({
    category: "Navigation",
    action: "Navigated to Analytics",
  })
}

const gaEvents = {
  broadcastReferral: broadcastReferral,
  bannerDismissed: bannerDismissed,
  broadcastAddedToCalendar: broadcastAddedToCalendar,
  broadcastFilterQuery: broadcastFilterQuery,
  broadcastsFilteredByTag: broadcastsFilteredByTag,
  broadcastFilteredByStatus: broadcastFilteredByStatus,
  broadcastsPreviewLiveBroadcast: broadcastsPreviewLiveBroadcast,
  navigateToAnalytics: navigateToAnalytics,
}

export default gaEvents
