import React from "react";
import { atcb_init, atcb_action } from "add-to-calendar-button";
import { IconCalendarPlus } from '@tabler/icons';

import gaEvents from '../events';

const DEFAULT_DURATION_HOURS = 3

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

function getLocalDateStr (scheduled_datetime) {
  const year = scheduled_datetime.getFullYear()
  const month = scheduled_datetime.getMonth() + 1
  const day = scheduled_datetime.getDate()

  return `${year}-${month}-${day}`
}

function getLocalTimeStr (scheduled_datetime) {
  let hours = scheduled_datetime.getHours()
  let minutes = scheduled_datetime.getMinutes()

  if (hours < 10) {
    hours = `0${hours}`
  }

  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  return `${hours}:${minutes}`
}

function getEstimatedEndDateTime (start_datetime_str) {
  const estimatedEndDateTime = new Date(start_datetime_str)

  const estimatedEndHour = estimatedEndDateTime.getHours() + DEFAULT_DURATION_HOURS
  estimatedEndDateTime.setHours(estimatedEndHour)

  return estimatedEndDateTime
}

function addToCalendar(broadcast) {
  const descriptionStr = broadcast.title
  const broadcastUrl = `https://www.youtube.com/watch?v=${broadcast.platform_video_id}`
  const scheduledStartTime = broadcast.scheduled_start_time

  const startDateTime = new Date(scheduledStartTime)
  const estimatedEndDateTime = getEstimatedEndDateTime(scheduledStartTime)

  const startDateStr = getLocalDateStr(startDateTime)
  const startTimeStr = getLocalTimeStr(startDateTime)

  const endDateStr = getLocalDateStr(estimatedEndDateTime)
  const endTime = getLocalTimeStr(estimatedEndDateTime)

  atcb_action({
    name: `Live Stream: ${broadcast.channel.title}`,
    description: `Title: "${descriptionStr}"`,
    location: broadcastUrl,
    startDate: startDateStr,
    startTime: startTimeStr,
    endDate: endDateStr,
    endTime: endTime,
    options: ["Apple", "Google", "iCal", "Microsoft365", "Outlook.com", "MicrosoftTeams", "Yahoo"],
    timeZone: clientTimeZone,
    iCalFileName: "Reminder-Event",
  });

  gaEvents.broadcastAddedToCalendar(broadcast.platform_video_id, broadcast.channel.title, broadcast.title)
}

const AddToCalendarButton = ({broadcast}) => {
  React.useEffect( () => { atcb_init() }, []);

  return (
    <button
    type="button"
    className="mt-3 mb-3 inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm bg-blue-700 text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 bg-gradient-to-r from-primary-500 to-info-500 hover:from-warning-500 hover:to-success-500 font-inter"
    onClick={() => addToCalendar(broadcast)}
    >
      <IconCalendarPlus className="mr-2" size={20} stroke={1.5} />Add
    </button>
  )
}

export default AddToCalendarButton
