import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useBroadcastGrid() {
  const [isBroadcastGrid, setIsBroadcastGrid] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsBroadcastGrid(location.pathname === '/');
  }, [location]);

  return isBroadcastGrid;
}

export default useBroadcastGrid;
