import { createSlice } from '@reduxjs/toolkit'



export const tagSelectionSlice = createSlice({
  name: 'tagSelection',
  initialState: {
    filteredTags: [],
  },
  reducers: {
    toggleTag: (state, action) => {
      const tagIndex = state.filteredTags.indexOf(action.payload);
      if (tagIndex === -1) {
        state.filteredTags.push(action.payload);
      } else {
        state.filteredTags.splice(tagIndex, 1);
      }
    },
    clearTags: (state) => {
      state.value = []
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggleTag, clearTags } = tagSelectionSlice.actions

export default tagSelectionSlice.reducer
