import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import gaEvents from '../events';

import React from 'react'
import {Tooltip} from "@mantine/core";
import {
  IconBrandDisney,
  IconBuildingCastle,
  IconGasStation,
  IconPaw,
  IconMovie,
  IconWorldLatitude,
  IconGlobe,
  IconBuildingLighthouse,
  IconVideo,
  IconSleigh,
  IconChristmasTree,
  IconGauge,
  IconManualGearbox,
  IconBuildingCarousel,
  IconUsers,
  IconTower,
  IconRollercoaster,
  IconHomeQuestion,
} from '@tabler/icons';
import { atcb_action, atcb_init } from 'add-to-calendar-button'
import { useSelector, useDispatch } from 'react-redux'

import AddToCalendarButton from './AddToCalendarButton';
import { toggleTag, clearTags } from '../features/tagSelection/tagSelection'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const components = {
  'IconBrandDisney': IconBrandDisney,
  'IconBuildingCastle': IconBuildingCastle,
  'IconTower': IconTower,
  'IconGasStation': IconGasStation,
  'IconPaw': IconPaw,
  'IconMovie': IconMovie,
  'IconWorldLatitude': IconWorldLatitude,
  'IconGlobe': IconGlobe,
  'IconBuildingLighthouse': IconBuildingLighthouse,
  'IconVideo': IconVideo,
  'IconSleigh': IconSleigh,
  'IconChristmasTree': IconChristmasTree,
  'IconGauge': IconGauge,
  'IconManualGearbox': IconManualGearbox,
  'IconUsers': IconUsers,
  'IconBuildingCarousel': IconBuildingCarousel,
}

function KnownIconElement(tag_obj, tagStatuses, toggleValue, registerKeyValuePair) {
  const dispatch = useDispatch()
  const tagName = tag_obj['name']
  const isSelected = tagStatuses[tagName]
  const iconComponentName = tag_obj['icon']

  const props = {size: 30, stroke: 1.5, className: 'inline px-1 hover:bg-primary-100 hover:from-warning-500 hover:to-success-500' + (isSelected ? ' text-success-500' : ' text-gray-500')}

  if (iconComponentName in components) {
    var IconComponent = components[iconComponentName]
  } else {
    var IconComponent = IconRollercoaster // default
  }

  if (!tagName in tagStatuses) {
    registerKeyValuePair(tagName, false)
  }

  function handleClick() {
    dispatch(toggleTag(tagName))
    toggleValue(tagName)
    gaEvents.broadcastsFilteredByTag(tagName)
  }

  return (
    <>
      <div className='inline'>
        <a
          href="#"
          className={classNames('text-gray-700 lg:text-sm block px-4 py-2 no-underline' + (isSelected ? ' text-success-500 hover:text-success-500' : ' text-gray-500'))}
          onClick={handleClick}
          >
            <IconComponent {...props} />
            {tagName}
        </a>
      </div>
    </>
  )
}

export default function TagDropdown({ values, tagStatuses, toggleValue, registerKeyValuePair }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full text-left text-gray-900 hover:bg-gray-5g0 hover:text-gray-900 block rounded-md py-2 px-3 lg:text-sm sm:text-base text-base font-medium no-underline">
          Filter
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {values.map((tag_icon_pair) => (
              <Menu.Item>
                {({ active }) => (
                  KnownIconElement(tag_icon_pair, tagStatuses, toggleValue, registerKeyValuePair)
                  )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
