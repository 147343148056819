import React from 'react'
import { Loader } from '@mantine/core';


function BroadcastLoader() {
  return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <Loader color="indigo" size="lg" />
      </div>
  )
}

export default BroadcastLoader
