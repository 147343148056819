import { Fragment, useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Disclosure, Menu, Popover, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, BellIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {Tooltip} from "@mantine/core";
import {
  IconBrandDisney,
  IconBuildingCastle,
  IconGasStation,
  IconPaw,
  IconMovie,
  IconWorldLatitude,
  IconGlobe,
  IconBuildingLighthouse,
  IconVideo,
  IconSleigh,
  IconChristmasTree,
  IconGauge,
  IconManualGearbox,
  IconBuildingCarousel,
  IconUsers,
  IconTower,
  IconRollercoaster,
} from '@tabler/icons';

import { useDispatch, useSelector } from 'react-redux'
import { selectStatus } from '../features/statusSelection.js/statusSelection'
import { ALL_BROADCASTS, LIVE_BROADCASTS, UPCOMING_BROADCASTS } from '../constants/statusConstants'
import { toggleTag } from '../features/tagSelection/tagSelection'

import gaEvents from '../events'
import TagDropdown from './TagDropdown'
import useBroadcastGrid from './UseBroadcastGrid'


const navigation = [
  { name: 'All', href: '#', status: ALL_BROADCASTS},
  { name: 'Live', href: '#', status: LIVE_BROADCASTS },
  { name: 'Upcoming', href: '#', status: UPCOMING_BROADCASTS },
]

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const components = {
  'IconBrandDisney': IconBrandDisney,
  'IconBuildingCastle': IconBuildingCastle,
  'IconTower': IconTower,
  'IconGasStation': IconGasStation,
  'IconPaw': IconPaw,
  'IconMovie': IconMovie,
  'IconWorldLatitude': IconWorldLatitude,
  'IconGlobe': IconGlobe,
  'IconBuildingLighthouse': IconBuildingLighthouse,
  'IconVideo': IconVideo,
  'IconSleigh': IconSleigh,
  'IconChristmasTree': IconChristmasTree,
  'IconGauge': IconGauge,
  'IconManualGearbox': IconManualGearbox,
  'IconUsers': IconUsers,
  'IconBuildingCarousel': IconBuildingCarousel,
}

// function useBroadcastGrid() {
//   const [isBroadcastGrid, setIsBroadcastGrid] = useState(false);
//   const location = useLocation();

//   useEffect(() => {
//     setIsBroadcastGrid(location.hash === '#');
//   }, [location]);

//   return isBroadcastGrid;
// }

function KnownIconElement(tag_obj, tagStatuses, toggleValue, registerKeyValuePair) {
  const dispatch = useDispatch()
  const tagName = tag_obj['name']
  const isSelected = tagStatuses[tagName]
  const iconComponentName = tag_obj['icon']

  const props = {size: 30, stroke: 1.5, className: 'inline px-1 hover:bg-primary-100 hover:from-warning-500 hover:to-success-500' + (isSelected ? ' text-success-500' : ' text-gray-500')}

  if (iconComponentName in components) {
    var IconComponent = components[iconComponentName]
  } else {
    var IconComponent = IconRollercoaster // default
  }

  if (!tagName in tagStatuses) {
    registerKeyValuePair(tagName, false)
  }

  function handleClick() {
    dispatch(toggleTag(tagName))
    toggleValue(tagName)
    gaEvents.broadcastsFilteredByTag(tagName)
  }

  return (
    <>
      <Tooltip label={tagName}>
        <div className='inline'>
          <IconComponent {...props} onClick={handleClick} />
        </div>
      </Tooltip>
    </>
  )
}

export function resetSearchFormInput() {
  document.getElementById("search").value = ""
}

const DisclosureButton = ({ item, key }) => {
  const itemCurrent = useSelector(state => state.statusSelection.filteredStatus === item.status)
  const dispatch = useDispatch()

  return (
      <Disclosure.Button
      key={key}
      filterName={item.status}
      as="a"
      href={item.href}
      className={classNames(
        itemCurrent ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
        'block rounded-md py-2 px-3 text-base font-medium no-underline'
      )}
      aria-current={itemCurrent ? 'page' : undefined}
      onClick={e => {
        e.preventDefault()
        dispatch(selectStatus(item.status))
        gaEvents.broadcastFilteredByStatus(item.status)
      }}
      >
      {item.name}
    </Disclosure.Button>
  )
}

// TODO - refactor this to use the same component as the other filter links
const FilterLink = ({ filterName, itemHref, children, tags }) => {
  const dispatch = useDispatch()
  const itemCurrent = useSelector(state => state.statusSelection.filteredStatus === filterName)

  return (
    <a
      key={children}
      href={itemHref}
      className={classNames(
      itemCurrent ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
      'rounded-md py-2 px-3 inline-flex items-center text-sm font-medium no-underline'
      )}
      aria-current={itemCurrent ? 'page' : undefined}
      onClick={e => {
        e.preventDefault()
        dispatch(selectStatus(filterName))
        gaEvents.broadcastFilteredByStatus(filterName)
      }}
    >
      {children}
    </a>
  )
}

export default function Header(props) {
  const isBroadcastGrid = useBroadcastGrid();

  function triggerGAA() {
    gaEvents.handleAnalyticsNavigation()
  }

  const onSearch = (e) => {
    e.preventDefault()
    props.onSearch(e.target.value)
  }

  const tags = props.tags
  const tagStatuses = props.tagStatuses
  const toggleValue = props.toggleValue
  const registerKeyValuePair = props.registerKeyValuePair

  return (
    <Disclosure as="header" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="relative z-10 flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="https://storage.googleapis.com/streamspheres-django/stream-park-logo.png"
                      alt="Your Company"
                    />
                    {/* ^ mobile logo */}
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="https://storage.googleapis.com/streamspheres-django/stream-park-logo.png"
                      alt="Your Company"
                    />
                  </div>
              </div>
              <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0 md:inset-0">
                <div className="w-full sm:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-indigo-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                      placeholder="Search"
                      type="search"
                      onChange={onSearch}
                    />
                  </div>
                </div>
              </div>

              <div className="relative z-10 flex items-center lg:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                <Menu as="div" className="relative ml-4 flex-shrink-0">
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block py-2 px-4 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <nav className="hidden lg:flex lg:space-x-8 lg:py-2" aria-label="Global">
            {isBroadcastGrid ? (
              <>
                {navigation.map((item) => (
                  <FilterLink key={item.name} filterName={item.status} itemCurrent={item.current} itemHref={item.href} > {item.name} </FilterLink>
                ))}
                <TagDropdown values={tags} tagStatuses={tagStatuses} toggleValue={toggleValue} registerKeyValuePair={registerKeyValuePair} />
              </>
            ) : (
              <Link to="/#" className="text-gray-900 hover:bg-gray-50 hover:text-gray-900 -gray-50 hover:text-gray-900',
      'rounded-md py-2 px-3 inline-flex items-center text-sm font-medium no-underline">
                Back to Broadcasts
              </Link>
            )}
              <Link to="/stats/rankings" className="text-gray-900 hover:bg-gray-50 hover:text-gray-900 -gray-50 hover:text-gray-900',
      'rounded-md py-2 px-3 inline-flex items-center text-sm font-medium no-underline" onClick={triggerGAA}>
                Analytics
              </Link>
            </nav>
          </div>

          <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <DisclosureButton item={item} key={item.name} />
              ))}
              <TagDropdown values={tags} tagStatuses={tagStatuses} toggleValue={toggleValue} registerKeyValuePair={registerKeyValuePair} />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
