import { MegaphoneIcon, XMarkIcon } from '@heroicons/react/24/outline'

import gaEvents from '../events';

const BANNER_KEY = 'dismissedBanner-20230211'

function dismissBanner() {
  closeBanner()
  registerBannerClosed()
  gaEvents.bannerDismissed()
}

function closeBanner() {
  const banner = document.getElementById('banner')
  banner.classList.add('hidden')
}

function registerBannerClosed() {
  localStorage.setItem(BANNER_KEY, 'true')
}

function isBannerDismissedInLocalStorage() {
  return localStorage.getItem(BANNER_KEY) === 'true'
}

export default function Banner({messageShort='', messageLong=''}) {
  return (
    <>
    {!isBannerDismissedInLocalStorage() && (
    <div id='banner' className="bg-gradient-to-r from-primary-500 to-info-500">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <span className="flex rounded-lg p-2 bg-gradient-to-r from-success-300 to-success-500">
              <MegaphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <p className="ml-3 truncate font-medium text-white font-inter mt-3">
              <span className="md:hidden text-xs">{messageShort}</span>
              <span className="hidden md:inline">{messageLong}</span>
            </p>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex rounded-md p-2 hover:bg-info-600 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              onClick={() => dismissBanner()}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
    )}
    </>
  )
}
