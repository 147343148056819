export const BROADCAST_LIST_REQUEST = 'BROADCAST_LIST_REQUEST'
export const BROADCAST_LIST_SUCCESS = 'BROADCAST_LIST_SUCCESS'
export const BROADCAST_LIST_FAIL = 'BROADCAST_LIST_FAIL'
export const BRADCAST_LIST_SET_STATUS_FILTER = 'BRADCAST_LIST_SET_STATUS_FILTER'
export const FILTER_BROADCASTS_BY_STATUS = 'FILTER_BROADCASTS_BY_STATUS'
export const FILTER_BROADCASTS_BY_QUERY = 'FILTER_BROADCASTS_BY_QUERY'

export const BROADCAST_DETAIL_REQUEST = 'BROADCAST_DETAIL_REQUEST'
export const BROADCAST_DETAIL_SUCCESS = 'BROADCAST_DETAIL_SUCCESS'
export const BROADCAST_DETAIL_FAIL = 'BROADCAST_DETAIL_FAIL'
