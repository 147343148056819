import axios from "axios"
import {
  BROADCAST_LIST_SET_STATUS_FILTER,
  BROADCAST_LIST_REQUEST,
  BROADCAST_LIST_SUCCESS,
  BROADCAST_LIST_FAIL,
  BROADCAST_DETAIL_REQUEST,
  BROADCAST_DETAIL_SUCCESS,
  BROADCAST_DETAIL_FAIL,
  FILTER_BROADCASTS_BY_STATUS,
  FILTER_BROADCASTS_BY_QUERY,
} from "../constants/broadcastConstants"

export const listBroadcasts = () => async (dispatch) => {
  try {
    dispatch ({ type: BROADCAST_LIST_REQUEST })

    const {data} = await axios.get('/api/broadcasts')

    // await new Promise(r => setTimeout(r, 5000));
    // console.log("data: ", data)

    dispatch ({
      type: BROADCAST_LIST_SUCCESS,
      payload: data
    })

  } catch(error) {
    dispatch ({ type: BROADCAST_LIST_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message })
  }
}

const statusFilter = (broadcasts, status) => {
  if (status === 'all') {
    return broadcasts
  } else if (status === 'live') {
    return broadcasts.filter(broadcast => broadcast.is_live)
  } else if (status === 'upcoming') {
    return broadcasts.filter(broadcast => !broadcast.is_live)
  }
}

export const filterBroadcastsByQuery = (query) => {
  return { type: 'FILTER_BROADCASTS_BY_QUERY', payload: { query } };
  // dispatch ({
  //   type: FILTER_BROADCASTS_BY_QUERY,
  //   payload: {
  //     query: query,
  //   }
  // })
}

export const filterBroadcastsByStatus = (broadcasts, status) => async (dispatch) => {
  dispatch ({
    type: FILTER_BROADCASTS_BY_STATUS,
    payload: {
      broadcasts: statusFilter(broadcasts, status),
    }
  })
}

export const listBroadcastDetails = (id) => async (dispatch) => {
  try {
    dispatch ({ type: BROADCAST_DETAIL_REQUEST })

    const {data} = await axios.get(`/api/broadcasts/${id}`)

    dispatch ({
      type: BROADCAST_DETAIL_SUCCESS,
      payload: data
    })

  } catch(error) {
    dispatch ({ type: BROADCAST_DETAIL_FAIL, payload: error.response && error.response.data.message ? error.response.data.message : error.message })
  }
}
