import React, { useEffect, useState } from 'react';
import {Tooltip} from "@mantine/core";
import {
  IconBrandDisney,
  IconBuildingCastle,
  IconGasStation,
  IconPaw,
  IconMovie,
  IconWorldLatitude,
  IconGlobe,
  IconBuildingLighthouse,
  IconVideo,
  IconSleigh,
  IconChristmasTree,
  IconGauge,
  IconManualGearbox,
  IconBuildingCarousel,
  IconUsers,
  IconTower,
  IconRollercoaster,
  IconHomeQuestion,
} from '@tabler/icons';
import { atcb_action, atcb_init } from 'add-to-calendar-button'
import { useSelector, useDispatch } from 'react-redux'

import EmbeddedLiveBroadcast from './EmbeddedLiveBroadcast';

import AddToCalendarButton from './AddToCalendarButton';
import { toggleTag, clearTags } from '../features/tagSelection/tagSelection'

import 'add-to-calendar-button/assets/css/atcb.css';


import gaEvents from '../events';

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

function removeEmojis(str) {
  return str.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
}

function formatDateTimeString(datetime_str) {
  const utcDatetime = new Date(datetime_str)
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone: userTimezone, timeZoneName: 'short', hour: 'numeric', minute: 'numeric', hour12: true }

  const localTime = utcDatetime.toLocaleString('en-US', options)

  return localTime
}

const components = {
  'IconBrandDisney': IconBrandDisney,
  'IconBuildingCastle': IconBuildingCastle,
  'IconTower': IconTower,
  'IconGasStation': IconGasStation,
  'IconPaw': IconPaw,
  'IconMovie': IconMovie,
  'IconWorldLatitude': IconWorldLatitude,
  'IconGlobe': IconGlobe,
  'IconBuildingLighthouse': IconBuildingLighthouse,
  'IconVideo': IconVideo,
  'IconSleigh': IconSleigh,
  'IconChristmasTree': IconChristmasTree,
  'IconGauge': IconGauge,
  'IconManualGearbox': IconManualGearbox,
  'IconUsers': IconUsers,
  'IconBuildingCarousel': IconBuildingCarousel,
}

function KnownIconElement(tag_obj, tagStatuses, toggleValue, registerKeyValuePair) {
  const dispatch = useDispatch()
  const tagName = tag_obj['name']
  const isSelected = tagStatuses[tagName]
  const iconComponentName = tag_obj['icon']

  const props = {size: 30, stroke: 1.5, className: 'inline px-1 hover:bg-primary-100 hover:from-warning-500 hover:to-success-500' + (isSelected ? ' text-success-500' : ' text-gray-500')}

  if (iconComponentName in components) {
    var IconComponent = components[iconComponentName]
  } else {
    var IconComponent = IconRollercoaster // default
  }

  if (!tagName in tagStatuses) {
    registerKeyValuePair(tagName, false)
  }

  function handleClick() {
    dispatch(toggleTag(tagName))
    toggleValue(tagName)
    gaEvents.broadcastsFilteredByTag(tagName)
  }

  return (
    <>
      <Tooltip label={tagName}>
        <div className='inline'>
          <IconComponent {...props} onClick={handleClick} />
        </div>
      </Tooltip>
    </>
  )
}

function UnknownIconElement(tagStatuses, toggleValue, registerKeyValuePair) {
  const dispatch = useDispatch()
  // const [isSelected, setIsSelected] = useUnknownIconSelectionStatus()

  const isSelected = tagStatuses['Unknown location']

  const props = {size: 30, stroke: 1.5, className: 'inline px-1 hover:bg-primary-100 hover:from-warning-500 hover:to-success-500' + (isSelected ? ' text-success-500' : ' text-gray-500')}

  function handleClick() {
    dispatch(toggleTag('Unknown location'))
    toggleValue('Unknown location')
    gaEvents.broadcastsFilteredByTag('Unknown location')
  }

  return (
    <>
      <Tooltip label={'Uknown location'}>
        <div className='inline'>
          <IconHomeQuestion {...props} onClick={handleClick} />
        </div>
      </Tooltip>
    </>
  )
}

function Broadcast({broadcast, tagStatuses, toggleValue, registerKeyValuePair}) {
  const openInNewTab = (url) => {
    window.open(url, '_blank').focus();
    gaEvents.broadcastReferral(broadcast.platform_video_id, broadcast.channel.title, broadcast.title)
  }

  const [titleStyle, setTitleStyle] = useState('child pt-4 ml-4 mt-2 font-bold w-60 font-inter');

  useEffect(() => {
    if (broadcast.channel.title.length >= 30) {
      setTitleStyle(titleStyle + ' text-xs');
    } else {
      setTitleStyle(titleStyle + ' text-base');
    }
  }, [broadcast.channel.title]);


  return (
    <div className="col-span-2 bg-gray-50 text-blue-800 shadow-lg border-[1px] border-gray-300 rounded">
      <div className="relative h-52 max-h-52 w-full overflow-hidden rounded-xl">
      {/* {broadcast.is_live &&
        <span className="absolute top-1 right-1 inline-flex items-center animate-pulse rounded-md bg-live-red px-2.5 py-0.5 text-sm font-medium text-white font-inter">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-white" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            LIVE
        </span>
      } */}
        <EmbeddedLiveBroadcast broadcast={broadcast} />
        {/* <img
          src={broadcast.thumbnail_url}
          alt={broadcast.title}
          className="h-full w-full object-cover object-center p-3"
        /> */}
      </div>

      <div className='relative border-t border-gray-300'>
        <div className='flex'>
          <span className="child relative inline-block ml-4 mt-4">
            <img
              className="h-12 w-12 ring-2 rounded-full text-gray-400"
              src={broadcast.channel.avatar_url}
              alt=""
            />
          </span>
          <h3 className={titleStyle}>{broadcast.channel.title}
          </h3>
        </div>


        <Tooltip label={broadcast.title}>
          <div>
            <p className="pt-3 px-3 text-gray-400 text-sm font-inter">{truncateString(removeEmojis(broadcast.title), 40)}</p>
          </div>
        </Tooltip>
      </div>

      {/* Tags */}
      <div className='px-3 py-2 border-t border-gray-300 text-gray-400 min-h-32'>
      { broadcast.tags?.length > 0 ? (
          broadcast.tags.map((tag_icon_pair) => (
            KnownIconElement(tag_icon_pair, tagStatuses, toggleValue, registerKeyValuePair)
            ))
          ) : (
            UnknownIconElement(tagStatuses, toggleValue, registerKeyValuePair)
        )
      }
      </div>

      {/* Viewers and button */}
      <div className='border-t border-gray-300 py-3 pb-1'>
          <div>
            <p className="inline pl-4 pr-1 pt-4 text-gray-900 text-sm font-bold font-interBold">{ broadcast.active_viewers} </p>
            <p className='inline text-sm text-gray-900 font-inter'>{broadcast.is_live ? "watching" : "waiting"}</p>
          </div>
          <div className=''>
            <p className='pl-4 text-sm text-gray-400 font-inter'>
              { broadcast.is_live ? formatDateTimeString(broadcast.actual_start_time) : formatDateTimeString(broadcast.scheduled_start_time) }
            </p>
          </div>
      </div>

      <div className="px-4 pt-0 pb-2 sm:flex sm:flex-row-reverse sm:px-6 border-t border-gray-400 bg-gray-100 rounded-bottom">
        <button
          type="button"
          className="mt-3 mb-3 inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm bg-blue-700 text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 bg-gradient-to-r from-primary-500 to-info-500 hover:from-warning-500 hover:to-success-500 font-inter"
          onClick={() => openInNewTab(`https://www.youtube.com/watch?v=${broadcast.platform_video_id}`)}
        >
          {broadcast.is_live ? "Watch" : "Join"}
        </button>
        { broadcast.is_live == false &&  (
          <AddToCalendarButton broadcast={broadcast} className="border-red-200" />
        )}
      </div>
    </div>
  )
}

export default Broadcast
