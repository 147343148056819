import React, { useEffect } from 'react';
import { BumpChart, LinePlot } from 'd3plus-react';
import axios from 'axios';

function mapDataToXY(data) {
  return data.map(item => {
    return {
      title: item.broadcast_channel_title,
      x: convertTimestamp(new Date(item.timestamp).getTime()),
      y: item.viewer_count
    }
  });
}


function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const localizedDate = date.toLocaleString();
  return localizedDate;
}

function groupByTimestamp(data) {
  const groupedData = {};
  data.forEach(datum => {
    const { timestamp } = datum;
    groupedData[timestamp] = groupedData[timestamp] || [];
    groupedData[timestamp].push(datum);
  });
  return groupedData;
}

function rankObjectsByViewerCount(data) {
  const rankedData = [];

  Object.keys(data).forEach(timestamp => {
    const objects = data[timestamp];
    objects.sort((a, b) => b.viewer_count - a.viewer_count);
    objects.forEach((object, index) => {
      const rankedObject = {
        broadcast_channel_title: object.broadcast_channel_title,
        label: object.broadcast_channel_title,
        rank: index + 1,
        timestamp: convertTimestamp(timestamp),
        data: [{ x: timestamp, y: object.viewer_count }]
      };
      rankedData.push(rankedObject);
    });
  });

  return rankedData;
}


function mapDataForBumpChart(data) {
  // Create a dictionary to store the viewer counts for each broadcast channel and timestamp
  const viewerCounts = {};

  // Loop through the data and add the viewer counts to the dictionary
  data.forEach(datum => {
    const { broadcast_channel_title, viewer_count, timestamp } = datum;
    viewerCounts[timestamp] = viewerCounts[timestamp] || {};
    viewerCounts[timestamp][broadcast_channel_title] = viewerCounts[timestamp][broadcast_channel_title] || [];
    viewerCounts[timestamp][broadcast_channel_title].push(viewer_count);
  });

  // Calculate the rankings within each timestamp group
  Object.keys(viewerCounts).forEach(timestamp => {
    const timestampData = viewerCounts[timestamp];
    Object.keys(timestampData).forEach(broadcastChannel => {
      const broadcastData = timestampData[broadcastChannel];
      const rankings = broadcastData
        .map((viewer_count, index) => ({ viewer_count, index }))
        .sort((a, b) => b.viewer_count - a.viewer_count)
        .map((rankedCount, index) => ({ rank: index + 1, count: rankedCount.viewer_count }));
      timestampData[broadcastChannel] = rankings;
    });
  });

  // Create an array of objects in the format expected by the BumpChart
  const chartData = [];
  Object.keys(viewerCounts).forEach(timestamp => {
    const timestampData = viewerCounts[timestamp];
    Object.keys(timestampData).forEach(broadcastChannel => {
      const broadcastData = timestampData[broadcastChannel];
      const dataPoints = broadcastData.map(({ rank, count }) => ({ x: new Date(timestamp), y: rank }));
      chartData.push({
        broadcast_channel_title: broadcastChannel,
        label: broadcastChannel,
        rank: broadcastData.findIndex(({ rank }) => rank === 1) + 1,
        timestamp: new Date(timestamp),
        data: dataPoints
      });
    });
  });

  return chartData;
}

function RankingScreen() {
  const [viewerCounts, setViewerCounts] = React.useState([]);
  useEffect(() => {
    const fetchViewerCounts = async () => {
      const { data } = await axios.get('/api/broadcasts/viewer-counts/30/');
      setViewerCounts(data);
    };
    fetchViewerCounts();
  }, []);


  const groupedData = groupByTimestamp(viewerCounts);
  const rankedData = rankObjectsByViewerCount(groupedData);

  return (
    <>
      {/* <BumpChart
        config={{
        data: rankedData,
        groupBy: 'broadcast_channel_title',
        label: d => d['label'],
        x: 'timestamp',
        y: 'rank',
        y2: 'rank',
        cache: false
      }}
    /> */}
    <LinePlot
  config={{
    data: mapDataToXY(viewerCounts),
    groupBy: 'title',
    backgroundConfig: {
      duration: 0
    },
    noDataMessage: false,
    // add label for x and y axis
    xConfig: {
      title: 'Time',
      titlePadding: 10,
      tickFormat: d => d.toLocaleString()
    },
    yConfig: {
      title: 'Viewers',
      titlePadding: 10
    },
    loadingHTML: 'loading...'
  }}
 />
  </>
  )

}

export default RankingScreen;
