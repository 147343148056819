import React, { useRef, useState } from 'react'
import gaEvents from '../events'

function EmbeddedLiveBroadcast({broadcast}) {
  const [isHovering, setIsHovering] = useState(false)

  const elementRef = useRef(null);

  function handleMouseEnter() {
    setIsHovering(true)
    if (broadcast.is_live) {
      gaEvents.broadcastsPreviewLiveBroadcast(broadcast.platform_video_id, broadcast.channel.title, broadcast.title)
    }
  }

  function handleMouseLeave() {
    setIsHovering(false)
  }

  const isMobileAppleDevice = () => {
    const userAgent = navigator.userAgent || window.opera;
    return (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    );
  };

  return (
    <div className="relative p-3 h-52 max-h-52 w-full overflow-hidden rounded-xl">
    {broadcast.is_live &&
        <span className="absolute top-1 right-1 inline-flex items-center animate-pulse rounded-md bg-live-red px-2.5 py-0.5 text-sm font-medium text-white font-inter">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-white" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            LIVE
        </span>
    }
    <button
      className="h-full w-full object-cover object-center bg-cover"
      style={{ backgroundImage: `url(${broadcast.thumbnail_url})`}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={elementRef}
    >
      {isHovering && broadcast.is_live && !isMobileAppleDevice() && (
        <iframe
          className="inset-0 pointer-events-none w-full h-full"
          src={`https://www.youtube.com/embed/${broadcast.platform_video_id}?autoplay=1&mute=1&controls=0&showinfo=0&loop=1&title=0`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      )}
    </button>
  </div>
  )
}

export default EmbeddedLiveBroadcast
