import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from './components/Footer'
import Header from './components/Header'
import BroadcastGridScreen from './screens/BroadcastsGridScreen';
import BroadcastScreen from './screens/BroadcastScreen';
import RankingScreen from './screens/RankingScreen'
import Banner from './components/Banner';
import store from './store';
import { filterBroadcastsByQuery } from './actions/broadcastActions';
import gaEvents from './events';
import { useSelector } from 'react-redux'
import React, {useState} from 'react'

function App() {
  const [filteredTag, setFilteredTag] = useState('')
  // const [filteredBroadcasts, setFilteredBroadcasts] = useState([])

  const [tagStatuses, setTagStatuses] = React.useState({
    'Unknown location': false,
  });

    // This function will toggle the value for a given key in the dictionary
  const toggleValue = key => {
    setTagStatuses({
      ...tagStatuses,
      [key]: !tagStatuses[key]
    });
  }

  // This function will add a new key-value pair to the dictionary if the key
  // is not already in the dictionary
  const registerKeyValuePair = (key, value) => {
    if (!(key in tagStatuses)) {
      setTagStatuses({
        ...tagStatuses,
        [key]: value
      });
    }
  }

  const onSearch = query => {
    store.dispatch(filterBroadcastsByQuery(query))
    gaEvents.broadcastFilterQuery(query)
  }

  const tags = useSelector(state => state.broadcastList.broadcasts.reduce((acc, broadcast) => {
    broadcast.tags.forEach(tag => {
      if (!acc.some(t => t.name === tag.name)) {
        acc.push(tag)
      }
    })
    acc.sort((a, b) => a.name.localeCompare(b.name))
    return acc
  }, []))

  return (
    <Router>
      <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
      <Header
        onSearch={onSearch}
        tags={tags}
        tagStatuses={tagStatuses}
        setTagStatuses={setTagStatuses}
        toggleValue={toggleValue}
        registerKeyValuePair={registerKeyValuePair}
      />
      <Banner messageShort={"New! Menu to filter by park or resort"} messageLong={"New! Menu to filter by park or resort"} />
      <main className='py-3'>
          <Routes>
            <Route path='/' element={
                <BroadcastGridScreen
                  {...store.getState()}
                  filteredTag={filteredTag}
                  setFilteredTag={setFilteredTag}
                  tagStatuses={tagStatuses}
                  setTagStatuses={setTagStatuses}
                  toggleValue={toggleValue}
                  registerKeyValuePair={registerKeyValuePair}
                />}
            />
            <Route path='/stream/:id' element={<BroadcastScreen/>} />
            <Route path='/stats/rankings' element={<RankingScreen/>} />
          </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
