import React, {useState, useEffect} from 'react'
import { Button, Card, Col, Image, ListGroup, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { listBroadcastDetails } from '../actions/broadcastActions'

import Loader from '../components/Loader'
import Message from '../components/Message'

function BroadcastScreen() {
  const { id } = useParams()

  const dispatch = useDispatch()
  const broadcastDetails = useSelector(state => state.broadcastDetail)
  const { loading, error, broadcast } = broadcastDetails

  useEffect(() => {
    dispatch(listBroadcastDetails(id))
  }, [])

  return (
    <div>
      <Link to='/' className='btn btn-light my-3'>Go Back</Link>
      {loading ? <Loader />
        : error
          ? <Message variant='danger'> {error} </Message>
        :
          (
            <Row>
              <Col md={6}>
                <Image src={broadcast.image} alt={broadcast.name} fluid />
              </Col>

              <Col md={3}>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <h3>{broadcast.channel}</h3>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    Title: {broadcast.title}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    {broadcast.is_live}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    Timing: {broadcast.timing_string}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    Description: {broadcast.description}
                  </ListGroup.Item>
                </ListGroup>
              </Col>

              <Col md={3}>
                <Card>
                  <ListGroup variant='flush'>
                    <ListGroup.Item>
                      <Row>
                        <Col>Status:</Col>
                        <Col>
                          <strong>{broadcast.isLive ? "live" : "upcoming"}</strong>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>Viewers:</Col>
                        <Col>
                          <strong>{broadcast.active_viewers}</strong>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Button className='btn-block' type='button'>Join</Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          )
      }
    </div>
  )
}

export default BroadcastScreen
