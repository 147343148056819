import { createSlice } from '@reduxjs/toolkit'

import { ALL_BROADCASTS } from '../../constants/statusConstants'

export const statusSelectionSlice = createSlice({
  name: 'statusSelection',
  initialState: {
    filteredStatus: ALL_BROADCASTS,
  },
  reducers: {
    selectStatus: (state, action) => {
      state.filteredStatus = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { selectStatus } = statusSelectionSlice.actions

export default statusSelectionSlice.reducer
